import React from "react";

function PlayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#ffffff" fillRule="nonzero">
          <path d="M13.75 8.412l24.417 12.705a3.25 3.25 0 010 5.766L13.75 39.588A3.25 3.25 0 019 36.705v-25.41a3.25 3.25 0 014.549-2.98l.201.097z"></path>
        </g>
      </g>
    </svg>
  );
}

export default PlayIcon;
