const CalculateSpacing = (spacing) => {
  let padding = ""
  switch (spacing) {
    case "360": {
      padding = "padding-bottom-208";
      break
    }
    case "320": {
      padding = "padding-bottom-200";
      break
    }
    case "184": {
      padding = "padding-bottom-112";
      break
    }

    case "160": {
      padding = "padding-bottom-96";
      break
    }
    case "104": {
      padding = "padding-bottom-64-hero-no-content";
      break
    }
    case "80": {
      padding = "padding-bottom-64";
      break
    }
    case "64": {
      padding = "padding-bottom-40";
      break
    }

    case "32": {
      padding = "padding-bottom-24";
      break
    }
    case "24": {
      padding = "padding-bottom-16";
      break
    }
    case "0": {
      padding = "padding-bottom-zero";
      break
    }

    default: {
      padding = "padding-bottom-zero";
    }
  }

  return padding
}

export default CalculateSpacing

