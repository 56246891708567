import Player from "@vimeo/player"

const StopAllVideos = (id) => {
  const videos = document.querySelectorAll(".vimeo-player")
  if (videos) {
    videos.forEach(function(iframe) {
      if (iframe) {
        if (iframe.dataset.vimeoId !== id) {
          const player = new Player(iframe)
          if (player) {
            if (player.ready()){
                player.getPaused().then(function(paused) {
                  if (!paused) {
                    player.pause()
                    player.setCurrentTime(0);
                    player.unload();
                    iframe.classList.remove("playing")
                  }
                }).catch(function(error) {
                  console.log(error)
                })
            }
          }
        }
      }
    })
  }
}

export default StopAllVideos

