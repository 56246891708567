import React, { useEffect, useState } from "react"
import { gsap } from "gsap"
import Player from "@vimeo/player"
import PlayIcon from "../svg/play"

import {
  BrowserView,
  MobileView,
  isBrowser,
} from "react-device-detect"
import CalculateSpacing from "../functionCalculateSpacing"
import StopAllVideos from "../functionStopAllVideos"

// let player = null
// let videoIsPlaying = false

const ClicableVideoSection = ({ videos, spacing = 184, configuration = [] }) => {

  const [volume, setVolume] = useState(0)
  useEffect(() => {
    // if (!initialize) {

    // setInitialize(true);
    // let iframe = document.querySelector("#vimeo-video-embed-" + video)

    // const storeSRC = iframe.dataset.src;
    // delete iframe.dataset.src;
    // iframe.src = storeSRC;

    // player = new Player(iframe);

    if (isBrowser) {

      // player.play()
      // player.setVolume(0)
      // videoIsPlaying = false


      // Variables
      // ************

      var button = document.querySelector("#music-button-" + video);
      var followArea = document.querySelector("#container-" + video)
      var page = document.querySelector("#vimeo-embed-" + video)

      // Follow Animation
      // ************
      var moveCircle = (e) => {
        gsap.to(button, 0.25, {
          css: {
            left: e.clientX,
            top: e.clientY
          }
        })
      }

      // Event Listeners
      // ************

      page.addEventListener("mousemove", moveCircle)
      // followArea.forEach(function(el) {
      followArea.addEventListener("mouseover", (e) => {
        gsap.to(button, 0, {
          css: {
            transform: `translate(-50%, -50%)`,
            left: e.clientX,
            top: e.clientY
          }
        })
        gsap.to(button, 0, {
          scale: 1,
          autoAlpha: 1
        })

      })
      followArea.addEventListener("mouseout", () => {
        gsap.to(button, 0, {
          scale: 0,
          autoAlpha: 0
        })
      })

      // followArea.addEventListener("touchstart", () => {
      //     //console.log("touchstart")
      // })

      // followArea.addEventListener("touchend", () => {
      //     //console.log("touchend")
      //   })

      followArea.addEventListener("mousedown", () => {

        gsap.to(button, 0.1, {
          css: { transform: `translate(-50%, -50%) scale(0.9)` }
        })

        StopAllVideos(video);

        const iframe = document.querySelector("#vimeo-video-embed-" + video)
        const playBtn = document.querySelector("#playBtn-" + video)
        const player = new Player(iframe);

        if (iframe.classList.contains('playing')) {
          player.setVolume(0)
          player.pause();
          iframe.classList.remove('playing');
          playBtn.classList.remove('playing');
          setVolume(0);
        } else {
          player.setVolume(1)
          player.play();
          iframe.classList.add('playing');
          playBtn.classList.add('playing');
          setVolume(1);
        }

      })

      followArea.addEventListener("mouseup", () => {
        gsap.to(button, 0.1, {
          css: { transform: `translate(-50%, -50%) scale(1)` }
        })
      })

    } else {
      const followArea = document.querySelector("#container-" + video)

      followArea.addEventListener("mousedown", () => {

        const iframe = document.querySelector("#vimeo-video-embed-" + video)
        const player = new Player(iframe);

        player.play()
        player.setVolume(1)
      })
    }
  }, [])

  if(videos.length < 1){
    console.log('Missing video');
    return (<></>);
  }

  const video = videos[0].item

  if(!video){
    console.log('Missing video');
    return (<></>);
  }

  let padding = CalculateSpacing(spacing)
  if(configuration.includes('centered')) {
    padding += ' container';
  }

  let videoClass = 'full-page-video-section video-section';
  if(configuration.includes('wide')) videoClass += ' wide';
  if(configuration.includes('square')) videoClass += ' square';

  // const [initialize, setInitialize] = useState(false);

  return (
    <div className={padding}>
      <div id={"container-" + video} className={videoClass}>
        <div id={"vimeo-embed-" + video} className={"vimeo-embed"}>
          <button className="vimeo-play" id={"playBtn-" + video} aria-label="Play video"><PlayIcon /></button>
          <iframe id={"vimeo-video-embed-" + video}
                  title={"vimeo player"}
                  src={"https://player.vimeo.com/video/" + video + "?autoplay=" + (isBrowser ? '0' : '0') + "&muted=1&loop=1&autopause=0&title=0&playsinline=0&portrait=1&controls=0"}
                  style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                  frameBorder="0"
                  webkitallowfullscreen={"true"} mozallowfullscreen={"true"} allowFullScreen
                  allow="autoplay; fullscreen"
                  className={"vimeo-player"}
                  data-vimeo-id={video}
          />
          <MobileView>
            <div className={"toggle-play-button"}>
              <div className={"toggle-play-button-text"}>
                PLAY
              </div>
            </div>
          </MobileView>
        </div>
      </div>
      <BrowserView>
        <div id={"music-button-" + video} className={"toggle-music-button"}>
          <div className={"toggle-music-button-text"}>
            {volume ? "STOP" : "PLAY"}
          </div>
        </div>
      </BrowserView>
    </div>
  )
}

export default ClicableVideoSection
